import React from 'react';
import styled from 'react-emotion';
import { t } from 'ttag';
import _get from 'lodash/get';
import Layout from '../../components/Layout';
import withLoading from '../../core/createLoadingAction';
import Login from '../../components/Login/Login';

const Heading = styled('h1')({ textAlign: 'center' });
const Error = styled('h3')({ color: '#C3310F', textAlign: 'center' });

export default withLoading(async ({ query = {}, store }) => {
  const requirePassword = parseInt(query.requirePassword, 10) === 1;
  const invalid = query.invalid === '1' || !!_get(store.getState(), 'form.login.values.invalid');
  const component = (
    <Layout>
      <Heading>{t`Prisijunkite`}</Heading>
      {invalid && <Error>{t`Neteisingi duomenys, bandykite dar kartą`}</Error>}
      <Login requirePassword={requirePassword} />
    </Layout>
  );
  return {
    title:  t`Prisijungti`,
    chunks: ['loginRoute'],
    component,
  };
});
