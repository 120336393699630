import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import { FieldRoot, Error } from './formStyles';

const InputField = ({ input, meta, ...props }) => (
  <FieldRoot>
    <Input {...input} {...props} />
    <Error>{meta.error}</Error>
  </FieldRoot>
);

InputField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta:  PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
};

export default InputField;
