import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import styled from 'react-emotion';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Input from '../Form/InputField';
import Button from '../Button';

const Form = styled('form')({
  maxWidth:    400,
  marginLeft:  'auto',
  marginRight: 'auto',
});

const Controls = styled('div')({
  marginTop: 10,
  textAlign: 'right',
});

const Login = ({ requirePassword, focus }) => (
  <Form method="POST" action="/login">
    <div>
      <Field
        component={Input}
        type="text"
        name="personalCode"
        placeholder={t`Asmens kodas`}
        autoComplete="off"
        autoFocus={focus === 'personalCode'}
      />
    </div>
    <div>
      <Field
        component={Input}
        type="text"
        name="firstName"
        placeholder={t`Vardas`}
        autoComplete="off"
        autoFocus={focus === 'firstName'}
      />
    </div>
    <div>
      <Field
        component={Input}
        type="text"
        name="lastName"
        placeholder={t`Pavardė`}
        autoComplete="off"
        autoFocus={focus === 'lastName'}
      />
    </div>
    {requirePassword && <div>
      <Field
        component={Input}
        type="password"
        name="password"
        placeholder={t`Slaptažodis`}
        autoFocus={focus === 'password'}
      />
      <input type="hidden" name="requirePassword" value="1" />
    </div>}

    <Controls>
      <Button type="submit">{t`Prisijungti`}</Button>
    </Controls>
  </Form>
);

Login.propTypes = {
  requirePassword: PropTypes.bool.isRequired,
  focus:           PropTypes.string,
};

Login.defaultProps = {
  focus: null,
};

const valueSelector = formValueSelector('login');

export default compose(
  reduxForm({ form: 'login' }),
  connect((state, { requirePassword: requirePasswordProp = false }) => {
    const requirePassword = !!valueSelector(state, 'requirePassword') || requirePasswordProp;
    let focus = null;
    if (!valueSelector(state, 'personalCode')) {
      focus = 'personalCode';
    } else if (!valueSelector(state, 'firstName')) {
      focus = 'firstName';
    } else if (!valueSelector(state, 'lastName')) {
      focus = 'lastName';
    } else if (requirePassword) {
      focus = 'password';
    }

    return {
      focus,
      requirePassword,
    };
  }),
)(Login);
