import { compose, branch, renderComponent } from 'recompose';
import styled from 'react-emotion';
import Link from '../Link';


const style = (props) => {
    const {
        disabled, fullWidth, size, variant,
    } = props;
    return {
        display:         'inline-block',
        width:           fullWidth ? '100%' : 'auto',
        textDecoration:  'none',
        border:          `1px solid ${disabled ? '#adadad' : '#5b94ff'}`,
        backgroundColor: disabled ? '#adadad' : '#5b94ff',
        color:           '#ffffff',
        padding:         '10px 20px',
        fontSize:        16,
        boxShadow:       '2px 2px 2px #d8d8d8',
        transition:      'all 200ms ease',
        borderRadius:    4,
        outline:         'none',
        cursor:          disabled ? 'not-allowed' : 'pointer',
        '&:hover':       !disabled && {
            boxShadow: '2px 2px 2px #b9b9b9',
        },
        '&:active':      {
            backgroundColor: '#5285E1',
        },

        margin: '0 4px',

        ...(size === 'small' && {
            padding:  '4px 8px',
            fontSize: 14,
        }),

        ...(variant === 'secondary' && {
            backgroundColor: '#ffffff',
            color:           '#5b94ff',
            '&:active':      {
                color: '#5285E1',
            },
        }),
        ...(variant === 'danger' && {
            borderColor:     '#C3310F',
            backgroundColor: '#C3310F',
            '&:active':      {
                backgroundColor: '#b02c0e',
            },
        }),
    };
};

const Button = styled('button')(style);

const LinkButton = styled(Link)(style);

export default compose(
    branch(({ type }) => type === 'button' || type === 'submit', renderComponent(Button)),
)(LinkButton);
